// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-examples-js": () => import("./../../../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../../../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-situation-updates-index-js": () => import("./../../../src/pages/situation-updates/index.js" /* webpackChunkName: "component---src-pages-situation-updates-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-us-page-js": () => import("./../../../src/templates/contact-us-page.js" /* webpackChunkName: "component---src-templates-contact-us-page-js" */),
  "component---src-templates-faq-page-js": () => import("./../../../src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-get-involved-page-js": () => import("./../../../src/templates/get-involved-page.js" /* webpackChunkName: "component---src-templates-get-involved-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-refugee-crisis-page-js": () => import("./../../../src/templates/refugee-crisis-page.js" /* webpackChunkName: "component---src-templates-refugee-crisis-page-js" */),
  "component---src-templates-what-we-do-page-js": () => import("./../../../src/templates/what-we-do-page.js" /* webpackChunkName: "component---src-templates-what-we-do-page-js" */)
}

